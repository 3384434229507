import React, { useState } from "react";
import axios from "axios";

export default function Licencia(props) {
    const [comercio, setComercio] = useState("");
    const [mes, setMes] = useState("");
    const [anio, setAnio] = useState("");
    const [dia, setDia] = useState("");

    const [error, setError] = useState("");
    const [claseMensaje, setClaseMensaje] = useState("");

    var data = {
        comercio: comercio,
        mes: mes,
        anio: anio,
        dia: dia,
    };

    const procesa = (event) => {
        event.preventDefault();

        axios.post("https://cartasonline.cl:7778/lic", data).then((response) => {
            if (response.data.code === "200") {
                setError(response.data.message);
                setClaseMensaje("MensajeOK");
            } else {
                setError(response.data.message);
                setClaseMensaje("MensajeError");
            }
        });
    };

    return (
        <div>
            <form>
                <label htmlFor="comercio">Comercio</label>
                <input
                    type="text"
                    className="form-control"
                    id="comercio"
                    maxLength="6"
                    onChange={(val) => {
                        setComercio(val.target.value.toUpperCase());
                    }}
                    placeholder="código comercio"
                    value={comercio}
                />

                <label htmlFor="anio">Hasta</label>

                <select
                    id="mes"
                    className="form-control"
                    onChange={(val) => {
                        setMes(val.target.value);
                    }}
                    value={mes}
                >
                    <option value="00">Seleccione</option>
                    <option value="01">Enero</option>
                    <option value="02">Febrero</option>
                    <option value="03">Marzo</option>
                    <option value="04">Abril</option>
                    <option value="05">Mayo</option>
                    <option value="06">Junio</option>
                    <option value="07">Julio</option>
                    <option value="08">Agosto</option>
                    <option value="09">Septiembre</option>
                    <option value="10">Octubre</option>
                    <option value="11">Noviembre</option>
                    <option value="12">Diciembre</option>
                </select>

                <select
                    id="anio"
                    className="form-control"
                    onChange={(val) => {
                        setAnio(val.target.value);
                    }}
                    value={anio}
                >
                    <option value="00">Seleccione</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                </select>

                <label htmlFor="dia">Password</label>
                <input
                    type="password"
                    className="form-control"
                    id="dia"
                    onChange={(val) => {
                        setDia(val.target.value);
                    }}
                    value={dia}
                    placeholder="password"
                />

                <br />
                <button className="btn btn-primary" onClick={procesa}>
                    Generar
                </button>
                <div id="error" className={claseMensaje}>
                    {error}
                </div>
            </form>
        </div>
    );
}
