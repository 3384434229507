//import logo from './logo.svg';
import logo3pro from './img/logo3pro.svg';
import './App.css';
import Licencia from './componentes/licencia';

function App() {

  return (
    <div className="App">
      <header className="App-header">

        {/* <img src={logo} className="App-logo" width="300px" height="300px" alt="logo" /> */}
        <img src={logo3pro} className="App-logo" width="200px" height="200px" alt="logo" />

        <Licencia></Licencia>

      </header>
    </div>
  );
}

export default App;
